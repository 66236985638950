<template>
  <div class="page">
    <div class="top">
      <div class="leftMain">
        <img src="../assets/img/logo.png" class="showimg" alt="" />
      </div>
      <div class="centerName">{{ $t('Batch.balance') }}</div>
      <div class="rightMain">
        <div class="language" @click="change()">
          <img v-if="language" src="../assets/img/en.png" alt="" />
          <img v-else src="../assets/img/cn.png" alt="" />
        </div>
        <div class="but" @click="toggleDrawer">
          <img src="../assets/img/navigation.png" alt="" />
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      :before-close="handleDrawerClose"
      direction="rtl"
      :size="260"
    >
<!-- 导航组件 -->
      <menulist @handleMenuSelect="handleMenuSelect"></menulist>
    </el-drawer>

    <div class="chooseWallet">
      <!-- 选择区块链 -->
      <div class="line">
        <div><img src="../assets/img/decorate.png" alt="" />{{ $t('Batch.chooseChain') }}</div>
      </div>
      <el-select v-model="chain" @change="changeChain" placeholder="请选择">
        <el-option
          v-for="item in chainList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 导入地址 -->
        <div class ="line">
        <el-button type="primary" @click="toPr">{{ $t('Batch.import') }}</el-button>
        <div class="text">
          {{ $t('Batch.WalletQuantity') }}<span>{{ wallentSize }}</span>{{ $t('Batch.Quantity') }}:<span>{{ totalAmount }}</span>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column type="index" :label="$t('Batch.num')" width="80" align="center">
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('Batch.WalletAddresses')"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="price" :label="$t('Batch.money')" align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="chooseWallet">
      <!-- 选择代币 -->
      <div class="line">
        <div><img src="../assets/img/decorate.png" alt="" />{{ $t('Batch.chooseToken') }}</div>
        <!-- 导入代币 -->
        <div class="button" @click="toDO">{{ $t('Batch.importToken') }}</div>
      </div>
      <el-select v-model="chooseCoin" placeholder="请选择">
        <el-option
          v-for="item in tokenList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <el-dialog
      :title="$t('Batch.importToken')"
      :visible.sync="dialog"
      width="80%"
      center
    >
      <el-input v-model="importCoin" :placeholder="$t('Batch.importToken')"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importToken">{{ $t('Batch.sure') }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('Batch.import')"
      :visible.sync="dialogPr"
      width="80%"
      center
    >
      <el-input v-model="importPrivateKey" :placeholder="$t('Batch.import')" type="textarea" rows="5"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importAddress">{{ $t('Batch.sure') }}</el-button>
      </span>
    </el-dialog>

    <div class="allButton">
      <!-- 查询 -->
      <div class="sendButton" @click="starting">{{ $t('Batch.select') }}</div>
    </div>
  </div>
</template>

<script>
import menulist from '../components/menu.vue'
import { getChain } from '../chain/ChainFactory.js'
export default {
  components: {
    menulist: menulist,
  },
  data() {
    return {
      importPrivateKey:'',
      chooseBut: false,
      num: 1,
      language: false,
      drawerVisible: false,
      dialog:false,
      dialogPr:false,
      chainList: [
        {
          value: 'ETH',
          label: 'Ethereum',
        },{
          value: 'BSC',
          label: 'BNB Chain'
        }
      ],
      chain: 'BSC',
      tokenList: [],
      importCoin:'',
      chooseCoin:'',
      tableData:[],
      chainObj:{}
    }
  },
  computed: {
    // 计算数据列表的长度
    wallentSize() {
      return this.tableData.length;
    },
    totalAmount(){
      return this.tableData.reduce((acc, cur) => {
        return acc + Number(cur.price)
      }, 0)
    }
  },
  methods: {
    changeChain(){
      this.tableData=[];
      this.chooseCoin='';
      this.tokenList=[];
      this.importPrivateKey="";
      this.chainObj = getChain(this.chain);
    },
    async importAddress(){
      if(this.importPrivateKey===''){
        this.$message.error("输入数据不能为空");
      }
      var line = this.importPrivateKey.split("\n");
      console.log("import data",line)
      for(var i=0;i<line.length;i++){
        this.tableData.push({
          address: '',
          price: 0,
          privateKey:line[i]
        })
      }
      this.tableData = await this.chainObj.getAddrbyPrivateKey(this.tableData);
      this.$message.info("导入成功");
      this.dialogPr=false;
    },
    change() {
      console.log('切换语言', this.$i18n.locale)
      if (this.$i18n.locale === 'en') {
        this.language = false
        this.$i18n.locale = 'zh'
      } else if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en'
        this.language = true
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible
    },
    handleDrawerClose() {
      this.drawerVisible = false
    },
    handleMenuSelect(index) {
      console.log('选中菜单项: ', index)
    },
    toDO() {
      this.dialog = true;
    },
    toPr() {
      this.dialogPr = true;
    },
    async importToken(){
      if(this.importCoin===''){
        this.$message.error("输入数据不能为空");
        return;
      }
      var symbol = await this.chainObj.getTokenByAddr(this.importCoin);
      this.tokenList.push({
          value: this.importCoin,
          label: symbol
        });
      this.$message.info("导入成功");
      this.dialog = false;
    },
    async starting(){
      if(this.tableData.length==0){
        this.$message.error("请导入地址");
        return
      }
      if(this.chooseCoin===''){
        this.$message.error("请先选代币");
        return
      }
      console.log("选择的代币",this.chooseCoin);
      await this.chainObj.batchCheckBalances(this.chooseCoin,this.tableData);
    }
  },
  created() {
    var i18n = this.$i18n.locale
    switch (i18n) {
      case 'zh':
        this.language = false
        break
      case 'en':
        this.language = true
        break
    }
    this.chainObj = getChain(this.chain);
  },
  mounted() {
    let Main = document.querySelector('.page')
    let MainW = Main.getBoundingClientRect().width
    Main.style.height = (MainW / 750) * 1500 + 'px'
  },
}
</script>

<style scoped>
body{
  margin: 0;padding: 0;
}
.row :deep(.el-menu) {
  background-color: #eee;
  border-right: 0px;
}
.row {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100%;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 4%;
}
.page {
  background: #fafafa;
}
.chooseWallet {
  width: 90%;
  margin: 5% auto;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 4px 7px 0 #00000012;
}
.leftMain{width: 20%;}
.centerName{
  width: 80%;
  text-align: center;
  color: #212020;
  font-weight: bold;
  font-size: 18px;
}
.rightMain{
  width: 20%;
  display: flex;
}
.but{
  padding-left: 25%;
}
.chooseWallet .line {
  padding: 5% 5% 0 5%;
  display: flex;
  align-items: baseline;
  font-weight: 400;
  color: #00133a;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chooseWallet .line img {
  margin-right: 5px;
}
.chooseButton {
  width: 26%;
  height: 30px;
  border-radius: 8px;
  display: flex;
  border: 1px solid #246bfd;
  color: #246bfd;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.chooseButton:nth-child(1) {
  background: #246bfd;
  color: #fff;
}
.chooseButtonLength {
  width: 17%;
  height: 30px;
  border-radius: 8px;
  display: flex;
  border: 1px solid #246bfd;
  color: #246bfd;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.chooseButtonLength:nth-child(1) {
  background: #246bfd;
  color: #fff;
}
.chooseWalletMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5% 5% 0 5%;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upButton {
  margin-bottom: 5%;
  width: 13%;
  height: 30px;
  border-radius: 8px;
  background: #246bfd;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
}
.el-input-number {
  width: 90% !important;
}
.el-input-number__decrease,
.el-input-number__increase {
  background: #246bfd14 !important;
}
.el-input-number__decrease.is-disabled,
.el-input-number__increase.is-disabled {
  color: #246bfd !important;
}
.button {
  padding: .5% 2%;
  height: 30px;
  border-radius: 10px;
  background: #246bfd;
  color: #fff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}
.el-submenu__title:hover {
  color: #246bfd !important;
}
.logo2 {
  margin: 0 auto;
  display: block;
}
.el-menu {
  border-right: 0;
}
.navigationIcon {
  margin-right: 8px;
}
.download {
  width: 15%;
  height: 30px;
  border-radius: 8px;
  color: #246bfd;
  border: 1px solid #246bfd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar {
  margin: 0 auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-bar span {
  color: #00133a;
  font-weight: bold;
}
.progress {
  margin: 10% auto;
}
.progress-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3%;
}
.address,
.words,
.secret {
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-around;
  margin: 0 5%;
  width: 100%;
}
.address span,
.words span,
.secret span {
  color: #4d4d4d;
  background: #246bfd0d;
  overflow: hidden;
  width: 84%;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  padding: 2%;
  margin: 1.5% 0;
}
.address b,
.words b,
.secret b {
  color: #246bfd;
}
.el-select {
  width: 90%;
  margin: 5%;
}
.text {
  font-size: 12px;
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}
.text span {
  display: block;
  font-size: 16px;
  color: #246bfd;
  margin: 0 5px;
  line-height: 30px;
}
.el-button {
  background: #246bfd !important;
  border-radius: 6px;
  opacity: 1;
  border: 1px solid #246bfd;
  background: #246bfd;
  color: #fff;
  font-size: 14px;
}
.el-table {
  margin: 5% 2%;
  width: 96% !important;
}
.el-table thead {
  border-radius: 6px 6px 0 0;
  opacity: 1;
  background: #e4edfe !important;
}
.el-table th.el-table__cell,
.el-table tr {
  background: none !important;
  color: #246bfd !important;
}
.page /deep/ .el-dialog{
  border-radius: 16px !important;
}
.allButton{
  width: 90%;
  display: flex;
  margin: 5% auto;
  justify-content: space-evenly;
  align-items: center;
}
.sendButton{
  border-radius: 40px;
  padding: 4% 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #246bfd;
  color: #fff;
  font-weight: bold;
}
.starting{
  color: #246bfd;
  background: #E4EDFE;
  font-weight: bold;
}
</style>