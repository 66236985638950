import EthBlockchain from '@/chain/EthBlockchain.js';
// import SolBlockchain from '@/chain/SolBlockchain.js';
import BnbBlockchain from '@/chain/BnbBlockchain.js';

export function getChain(chain){
    switch(chain) {
        case 'ETH':
            return new EthBlockchain();
        case 'BSC':
             return new BnbBlockchain();
        case 'SOL':
            // return new SolBlockchain();
            break;
        default:
            throw new Error('Unsupported chain');
    }
}