import VueRouter from 'vue-router'

import generate from '../views/generate'
import sending from '../views/sending'
import imputation from '../views/imputation'
import balance from '../views/balance'
import other from '../views/other'

// 创建一个路由器
const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/generate', // 重定向
      component: generate
    },
    {
      path: '/generate',
      name: 'generate',
      component: generate
    },
    {
      path: '/sending',
      name: 'sending',
      component: sending
    },
    {
      path: '/imputation',
      name: 'imputation',
      component: imputation
    },
    {
      path: '/balance',
      name: 'balance',
      component: balance
    },
    {
      path: '/other',
      name: 'other',
      component: other
    },
  ]
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从那个路径跳转而来
  // next 是一个函数，表示放行
  //   next() 放行   next('/login') 强制跳转
  // if(to.path === '/login') return next()
  // const tokenStr = sessionStorage.getItem('token');
  // if(!tokenStr) return next('/login')
  // next()
// })

export default router