<template>
  <div class="page">
    <div class="top">
        <div class="leftMain">
            <img  src="../assets/img/logo.png" class="showimg" alt="">
        </div>
        <div class="centerName">{{ $t('Batch.sending') }}</div>
        <div class="rightMain">
          <div class="language" @click="change()">
            <img v-if="language"  src="../assets/img/en.png" alt="">
            <img v-else src="../assets/img/cn.png" alt="">
          </div>
          <div class="but" @click="toggleDrawer">
            <img src="../assets/img/navigation.png" alt="">
          </div>
        </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      :before-close="handleDrawerClose"
      direction="rtl"
      :size="260"
    >
<!-- 导航组件 -->
      <menulist @handleMenuSelect="handleMenuSelect"></menulist>
    </el-drawer>

    <div class="chooseWallet">
      <!-- 选择区块链 -->
        <span class="line"><img src="../assets/img/decorate.png" alt="">{{ $t('Batch.chooseChain') }}</span>
        <div class="chooseWalletMain">
          <el-select v-model="chooseChain" @change="handleChange">
            <el-option
              v-for="item in chainList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <!-- 链接钱包 -->
          <div class="wallet" @click="connentWallet">{{ $t('Batch.LinkWallet') }}</div>
        </div>
    </div>

    <div class="chooseWallet">
      <!-- 目标代币 -->
        <span class="line"><img src="../assets/img/decorate.png" alt="">{{ $t('Batch.TargetToken') }}</span>
        <div class="chooseWalletMain">
          <div class="send">
            <span>{{ $t('Batch.sending') }} {{chooseChain}}：</span>
            <el-switch
              v-model="sendCoin"
              active-color="#246BFD"
              inactive-color="#cccccc">
            </el-switch>
          </div>
          <div class="contentInput" v-if="!sendCoin">
            <el-input v-model="inputToken" :disabled="sendCoin" placeholder="token"></el-input>
            <p style="font-size: 12px; color: red; ">{{ $t('Batch.tips',[constructorAddr()]) }}</p>
          </div>
          <div class="sendNum">
            <!-- 发送统一数量： -->
            <span>{{ $t('Batch.SendQuantity') }}</span>
            <el-switch
              v-model="sendAmount"
              active-color="#246BFD"
              inactive-color="#cccccc">
            </el-switch>
          </div>
          <div class="contentInput"><el-input v-model="equal" :disabled="!sendAmount" :placeholder="$t('Batch.SameQuantity')"></el-input></div>
          <div class="addressList">
            <!-- 地址列表 -->
            <span>{{ $t('Batch.addressesList') }}</span>
            <span>{{ walletSize}} {{ $t('Batch.tenWallet') }}</span>
          </div>
          <div class="addressListContent">
              <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8}"
                @blur="addressInput"
                :placeholder="$t('Batch.handsAdress')"
                v-model="addressList">
              </el-input>

              <el-alert
                style="margin-top: 5px;"
                v-if="invalidLines.length > 0"
                type="error"
                closable
              >
              <ul class="error-list">
                <li v-for="error in invalidLines" :key="error.line">
                  第 {{ error.line }} 行数据 "{{ error.address }}" 不是有效地址
                </li>
              </ul>
            </el-alert>
          </div>
        </div>
    </div>

    <div class="allButton">
      <!-- 授权 -->
      <div class="empowerButton" v-if="!sendCoin" @click="authorization">{{ $t('Batch.authorization') }}</div>
      <!-- 发送 -->
      <div class="sendButton" @click="sendButton">{{ $t('Batch.send') }}</div>
    </div>

    <div class="success">
      <span v-if="transactionHash!==''">{{ $t('Batch.success') }}</span>
      <p v-if="transactionHash!==''">transactionHash:{{transactionHash}}</p>
    </div>
  </div>
</template>

<script>
import menulist from '../components/menu.vue'
import { getChain } from '../chain/ChainFactory'
import Decimal from 'decimal.js'
import Web3 from 'web3';
const web3 = new Web3();
  export default {
    components: {
      menulist: menulist,
    },
    data(){
    return{
      addressList: '',
      chooseBut:false,
      num: 1,
      language:false,
      drawerVisible: false,
      number:'1',
      chainList: [{
          value: 'ETH',
          label: 'Ethereum'
        },{
          value: 'BSC',
          label: 'BNB Chain'
      }],
      chooseChain: 'ETH',
      sendCoin:false,
      sendAmount:false,
      inputToken:'',
      equal:'',
      sendList:[],
      chain:{},
      transactionHash:'',
      sendload:false,
      authorizationload:false,
      invalidLines: []
    }
  },
  computed: {
    walletSize() {
      return this.sendList.length;
    }
  },
  methods:{
    constructorAddr(){
      return this.chain.constructorAddr();
    },
    addressInput(){
      this.sendList = this.parseInput(this.addressList)
      console.log(this.sendList);
    },
    shwoAllPrize(){
      this.chooseBut = !this.chooseBut
      console.log('显示全部按钮');
    },
    handleChange(value) {
      this.chain = getChain(value)
      console.log("当前选择链", this.chain);
    },
    parseInput(input) {
        const result = [];
        const invalidLines = [];
        if (input.trim() !== '') {
          const lines = input.trim().split('\n');
          lines.forEach((line, index) => {
          if (line.trim() !== '') { // 忽略空行
            const [address, amount] = line.split(',').map(part => part.trim());
            if (address && web3.utils.isAddress(address)) { // 确保地址存在且合法
              result.push({ address: address, amount: parseFloat(amount) || 0 });
            } else {
              invalidLines.push({ line: index + 1, address }); // 记录不合法地址的行号和地址
            }
          }
          });
        }
        this.invalidLines = invalidLines;
        return result;
    },
    change(){
      console.log('切换语言',this.$i18n.locale);
      if(this.$i18n.locale ==='en'){
        this.language=false
        this.$i18n.locale='zh'
      }else if(this.$i18n.locale ==='zh'){
        this.$i18n.locale ='en'
        this.language=true
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    handleDrawerClose() {
      this.drawerVisible = false;
    },
    handleMenuSelect(index) {
      console.log('选中菜单项: ', index);
    },
    sendButton(){
      if(this.sendList.length==0){
        this.$message.error('地址列表不能为空');
        return;
      }
      if(!this.sendCoin && this.inputToken==''){
        this.$message.error('token不能为空');
        return;
      }
      let toatalAmout = this.calcTotalAmount();
      if(this.sendCoin){
          this.chain.getCurrentAmount().then(currentAmount=>{
            console.log(`currentAmount ${currentAmount} sendAmount ${toatalAmout}` );
            currentAmount = parseFloat(currentAmount); 
            if(currentAmount<=toatalAmout){
              this.$message.error('当前账户余额不足,请切换账号');
              return;
            }
            // 调用转账函数
            this.chain.batchTransfer(this.sendList,toatalAmout)
            .then(res=>{
                this.transactionHash=res.transactionHash;
                this.$message.info("转账成功!");
                console.log('Transaction successful with hash:', res);
            })
            .catch(error => {
                this.transactionHash=''
                this.$message.error(error.message);
                console.error('Transaction failed:', error);
            });
        });
      }else{
         this.chain.getTokenAmount(this.inputToken).then(currentAmount=>{
            console.log(`currentAmount ${currentAmount} sendAmount ${toatalAmout}` );
            currentAmount = parseFloat(currentAmount); 
            if(currentAmount<toatalAmout){
              this.$message.error("当前账户余额不足,请切换账号!");
              return;
            }
            this.chain.needApprove(this.inputToken,toatalAmout).then((res)=>{
              if(res){
                this.$message.error("转账前,前先授权!");
                return;
              }else{
                  const tempList = this.getSendTokenParam()
                  this.chain.batchTransferToken(this.inputToken,tempList).then((res)=>{
                  this.transactionHash=res.transactionHash;
                  this.$message.info("转账成功!");
                  return;
                },(error)=>{
                  this.transactionHash=''
                  this.$message.error(error);
                  console.error(error);
                })
              }
            })
         });
      }
    },
    getSendTokenParam(){
      let tempList = [];
      for (let itme of this.sendList){
        if(this.sendAmount){
          tempList.push({address:itme.address,amount:Number(this.equal)})
        }else{
          tempList.push({address:itme.address,amount:itme.amount})
        }
      }
      console.log("send param ",tempList);
      return tempList;
    },
    authorization(){
      let totalAmout = this.calcTotalAmount()
      if(totalAmout==0){
        this.$message.error("请检查转账金额是否正确");
        return;
      }
      if(this.inputToken == ''){
        this.$message.error('toekn不能是空');
        return;
      }
      this.chain.needApprove(this.inputToken,totalAmout).then((res)=>{
        if(res){
          this.chain.approve(this.inputToken,totalAmout).then((res)=>{
            this.$message.info("授权成功!");
          },(error)=>{
            console.error(error);
            this.$message.info("授权失败!",error);
          })
        }else{
          this.$message.info("当前金额不需要授权!");
        }
      })
    },
    calcTotalAmount() {
      let res = new Decimal(0)
      for (const list of this.sendList) {
        if (this.sendAmount) {
          res = res.plus(new Decimal(this.equal))
        } else {
          let amount = new Decimal(list.amount || 0)
          res = res.plus(amount)
        }
      }
      return res.toFixed(6)
    },
    connentWallet(){
      this.chain.connentWallet();
    }
  },
  created(){
    var i18n=this.$i18n.locale
      switch (i18n){
        case 'zh':
          this.language=false
          break;
        case 'en':
        this.language=true
          break;
      }
    this.chain = getChain(this.chooseChain)
  },
  mounted(){
  }
}
</script>

<style scoped>
body{
  margin: 0;padding: 0;
}
.row :deep(.el-menu) {
  background-color: #eee;
  border-right:0px
}
.row{position: absolute;z-index: 100;left: 0;width: 100%;}
.top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 4%;
}
.page{
  width: 100%;
  background: #fafafa;
}
.chooseWallet{
  width: 90%;
  margin: 5% auto;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 4px 7px 0 #00000012;
}
.leftMain{width: 20%;}
.centerName{
  width: 80%;
  text-align: center;
  color: #212020;
  font-weight: bold;
  font-size: 18px;
}
.rightMain{
  width: 20%;
  display: flex;
}
.but{
  padding-left: 25%;
}
.chooseWallet .line{
  padding: 5% 5% 0 5%;
  display: flex;
  align-items: baseline;
  font-weight: 400;
  color: #00133A;
}
.chooseWallet .line img{
  margin-right: 5px;
}
.chooseWalletMain{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding:5% ;
}
.content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
}
.el-input-number{
  width: 90% !important;
}
.el-input-number__decrease, .el-input-number__increase{
  background: #246bfd14 !important;
}
.el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
  color: #246BFD !important;
}

.el-submenu__title:hover{
  color: #246BFD !important;
}
.logo2{
  margin: 0 auto;
  display: block;
}
.el-menu{
  border-right:0
}
.navigationIcon{
  margin-right: 8px;
}
.download{
  width: 15%;
  height: 26px;
  border-radius: 8px;
  color: #246bfd;
  border: 1px solid #246bfd;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}
.address, .words, .secret{
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-around;
  margin: 0 5%;
  width: 100%;
}
.address span, .words span, .secret span{
  color: #4D4D4D;
  background: #246bfd0d;
  overflow: hidden;
  width: 84%;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  padding:2%;
  margin:1.5% 0;
}
.address b, .words b, .secret b{
  color: #246BFD;
}
.wallet{
  width: 24%;
  height: 34px;
  border-radius: 6px;
  opacity: 1;
  border: 1px solid #246bfd;
  background: #246bfd;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.el-input__inner{
  border: 0.5px solid #eeefef !important
}
.send,.sendNum,.addressList,.addressListContent,.Content{
  margin: 0 auto;
  width: 98%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3F485A;
}
.contentInput{
  margin: 5% auto;
  width: 100%;
}
.addressList{
  margin: 5% auto;
}
.addressListContent{
  flex-wrap: wrap;
  border: 0.5px solid #eeefef !important;
  border-radius: 8px;
}
.Content{
  color: #C9CDD4;
  margin: 2% 3%;
}
.allButton{
  width: 90%;
  display: flex;
  margin: 5% auto;
  justify-content: space-evenly;
  align-items: center;
}
.empowerButton,.sendButton{
  border-radius: 40px;
  padding: 4% 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #246bfd;
  color: #fff;
  font-weight: bold;
}
.sendButton{
  color: #246bfd;
  background: #E4EDFE;
  font-weight: bold;
}
.success{
  margin: 0 auto;
  width: 80%;
  padding-bottom: 15%;
}
.success span{
  text-align: center;
  display: block;
  color: #246BFD;
  font-size: 12px;
}
.success b{
  color: #246BFD;
  font-size: 10px;
  word-break: break-all;
  font-weight: 400;
}
.send{
  margin-bottom: 25px;
}

.error-list {
  list-style: none; /* 去掉前面的点 */
  padding: 0; /* 去掉默认内边距 */
  margin: 0; /* 去掉默认外边距 */
}

.error-list li {
  font-size: 14px; /* 设置字体大小 */
  text-align: left; /* 左对齐 */
  margin-bottom: 5px; /* 添加底部间距 */
}

</style>