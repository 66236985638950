<template>
  <div class="page">
    <div class="top">
        <div class="leftMain">
            <img  src="../assets/img/logo.png" class="showimg" alt="">
        </div>
        <div class="centerName">{{ $t('Batch.imputation') }}</div>
        <div class="rightMain">
          <div class="language" @click="change()">
            <img v-if="language"  src="../assets/img/en.png" alt="">
            <img v-else src="../assets/img/cn.png" alt="">
          </div>
          <div class="but" @click="toggleDrawer">
            <img src="../assets/img/navigation.png" alt="">
          </div>
        </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      :before-close="handleDrawerClose"
      direction="rtl"
      :size="260"
    >
      <!-- 导航组件 -->
      <menulist @handleMenuSelect="handleMenuSelect"></menulist>
    </el-drawer>

    <div class="chooseWallet">
      <!-- 选择区块链 -->
      <div class="line">
        <div><img src="../assets/img/decorate.png" alt="" />{{ $t('Batch.chooseChain') }}</div>
      </div>
      <el-select v-model="chain" @change="changeChain" placeholder="请选择">
        <el-option
          v-for="item in chainList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- 导入地址 -->
      <div class="lines">
        <el-button type="primary" @click="importAddresses">{{ $t('Batch.import') }}</el-button>
        <!-- 查询 -->
        <div class="text" v-loading.fullscreen.lock="fullscreenLoading" @click="find">
          {{ $t('Batch.select') }}
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column type="index" :label="$t('Batch.num')" width="80" align="center">
        </el-table-column>
        <el-table-column
          prop="address"
          :label="$t('Batch.WalletAddresses')"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="price" :label="$t('Batch.money')" align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="chooseWallet">
      <!-- 目标代币 -->
        <span class="line"><img src="../assets/img/decorate.png" alt="">{{ $t('Batch.TargetToken') }}</span>
        <div class="chooseWalletMain">
          <div class="send">
            <span>{{ $t('Batch.sending') }} {{chain }}：</span>
            <el-switch
              v-model="sendCoin"
              active-color="#246BFD"
              inactive-color="#cccccc">
            </el-switch>
          </div>
          <div class="contentInput"><el-input v-model="inputToken" v-if="!sendCoin" placeholder="token"></el-input></div>
          <div class="sendNum">
            <!-- 发送统一数量： -->
            <span>{{ $t('Batch.allSend') }}</span>
            <el-switch
              v-model="sendAmount"
              active-color="#246BFD"
              inactive-color="#cccccc">
            </el-switch>
          </div>
          <div class="sendMount"><el-input v-if="!sendAmount" v-model="sendMount" :placeholder="$t('Batch.sendQtyInput')"></el-input></div>

          <span>{{$t('Batch.receiveAddress') }}</span>
          <div class="sendMount">
            <el-input v-model="importAdress" :placeholder="$t('Batch.receiveAddress')"></el-input></div>
        </div>
    </div>

    <div class="allButton">
      <div class="sendButton" v-loading.fullscreen.lock="fullscreenLoading" @click="start">{{ $t('Batch.start') }}</div>
    </div>

    <div class="bottom">
      <div class="finish">
        <!-- 地址 执行结果 -->
        <span>#</span><span>{{ $t('Batch.addresses') }}</span><span>{{ $t('Batch.finish') }}</span>
      </div>
      <div class="finishBottom" v-for="(item,index) in results" :key="index">

        <div class="finishBottomList"><span>{{index+1}}</span><span>{{ item.address }}</span><span>{{ item.amount }}</span></div>
       </div>
    </div>

    <el-dialog
      :title="$t('Batch.handsAdress')"
      :visible.sync="dialog"
      width="80%"
      center
    >
      <el-input type="textarea" :rows="3" v-model="inputAdress" :placeholder="$t('Batch.addresses')"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importReceiveAddr">{{ $t('Batch.sure') }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('Batch.import')"
      :visible.sync="dialogPr"
      width="80%"
      center
    >
      <el-input v-model="importPrivateKey" :placeholder="$t('Batch.import')" type="textarea" rows="5"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="importAddres">{{ $t('Batch.sure') }}</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import menulist from '../components/menu.vue'
import { getChain } from '../chain/ChainFactory.js'
  export default {
    components: {
      menulist: menulist,
    },
    data(){
    return{
      fullscreenLoading: false,
      dialog:false,
      tableData:[],
      chainList: [
        {
          value: 'ETH',
          label: 'Ethereum',
        },{
          value: 'BSC',
          label: 'BNB Chain'
        }
      ],
      chain: 'ETH',
      chooseBut:false,
      language:false,
      drawerVisible: false,
      chooseChain: '',
      sendCoin:false,
      sendAmount:false,
      inputToken:'',
      sendMount:'',
      importAdress:'',
      inputAdress:'',
      chainObj:{},
      dialogPr:false,
      importPrivateKey:'',
      results:[],
      recevieList:[]
    }
  },
  methods:{
    importReceiveAddr(){
        this.receiveList=[];
        if(this.inputAdress===''){
          this.$message.error("请输入地址");
          return
        }
        // 按照\n分割
        this.inputAdress.split('\n').forEach((element,index) => {
          var time = element.split(',');
          if(time.length==2){
            this.receiveList.push({
              address:time[0],
              amount:time[1]
            })
          }else{
            this.$message.error("请检查第"+index+"行格式是否正确");
          }
        });
        console.log("导入后地址数据",this.receiveList);
        this.dialog=false;
    },

    async importAddres(){
      if(this.importPrivateKey===''){
        this.$message.error("输入数据不能为空");
      }
      var line = this.importPrivateKey.split("\n");
      for(var i=0;i<line.length;i++){
        this.tableData.push({
          address: '',
          price: 0,
          privateKey:line[i]
        })
      }
      this.tableData = await this.chainObj.getAddrbyPrivateKey(this.tableData);
      console.log("import data",this.tableData)
      this.dialogPr = false;
    },
    changeChain(){
      console.log("切换链",this.chain);
      this.chainObj = getChain(this.chain);
      this.tableData=[];
      this.inputToken='';
    },
    shwoAllPrize(){
      this.chooseBut = !this.chooseBut
      console.log('显示全部按钮');
    },
    handleChange(value) {
      console.log("生成钱包地址数量",value);
    },
    change(){
      console.log('切换语言',this.$i18n.locale);
      if(this.$i18n.locale ==='en'){
        this.language=false
        this.$i18n.locale='zh'
      }else if(this.$i18n.locale ==='zh'){
        this.$i18n.locale ='en'
        this.language=true
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    handleDrawerClose() {
      this.drawerVisible = false;
    },
    handleMenuSelect(index) {
      console.log('选中菜单项: ', index);
    },
    async start(){
      if(this.tableData.length ===0){
        this.$message.error("请先导入钱包");
        return;
      }
      if(this.inputToken==='' && this.sendCoin===false){
        this.$message.error("请输入代币");
        return;
      }

      if(this.importAdress===''){
        this.$message.error("请输入接收地址");
        return;
      }

      if(!this.sendAmount && this.sendMount ===''){
        this.$message.error("请输入发送金额");
        return
      }
      console.log("发送地址",this.tableData);
      console.log("接收地址",this.importAdress);
      console.log("是否全部发送",this.sendAmount);
      if(!this.sendAmount){
        console.log("发送的总金额",this.sendMount);
      }
      if(!this.sendCoin){
        console.log("发送的合约地址",this.inputToken);
      }
      // loading开始
      this.fullscreenLoading = true
      try{
          if(this.sendCoin){
            this.results = await this.chainObj.batchCollection(this.sendAmount,this.sendMount,
            this.importAdress,this.tableData);
            this.tableData = await this.chainObj.batchBalances(this.tableData);
            this.fullscreenLoading = true
          }else{
            this.results = await this.chainObj.batchCollectionToken(this.sendAmount,this.sendMount,
            this.importAdress,this.inputToken,this.tableData);
            this.tableData = await this.chainObj.batchCheckBalances(this.inputToken,this.tableData);
            this.fullscreenLoading = true
          }
        this.$message.info("归集交易成功");
      }catch(e){
        console.error(e);
        this.$message.error("归集交易失败",e);
      }finally{
        setTimeout(()=>{
          this.fullscreenLoading = false
        },3000)
      }
    },
    wallet(){
      console.log("链接钱包");
    },
    toDO(){
      this.dialog = true;
    },
    importAddresses(){
      this.dialogPr = true;
    },
    async find(){
      if(this.tableData.length ===0){
        this.$message.error("请先导入钱包");
        return;
      }
      if(this.inputToken==='' && this.sendCoin===false){
        this.$message.error("请输入代币");
        return;
      }
      // loading开始
      this.fullscreenLoading = true
      try{
        console.log(this.fullscreenLoading);
        if(this.sendCoin){
          this.tableData = await this.chainObj.batchBalances(this.tableData);
        }else{
          this.tableData = await this.chainObj.batchCheckBalances(this.inputToken,this.tableData);
        }
      }catch(e){
          console.error(e);
          this.$message.error("查询余额失败!");
      }finally{
          // loading结束
        setTimeout(()=>{
          this.fullscreenLoading = false
        },1000)
      }
    },
  },
  created(){
    var i18n=this.$i18n.locale
      switch (i18n){
        case 'zh':
          this.language=false
          break;
        case 'en':
        this.language=true
          break;
      }
    this.chainObj = getChain(this.chain);
  },
  mounted(){
  }
}
</script>

<style scoped>
body{
  margin: 0;padding: 0;
}
.row :deep(.el-menu) {
  background-color: #eee;
  border-right:0px
}
.row{position: absolute;z-index: 100;left: 0;width: 100%;}
.top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 4%;
}
.page{
  width: 100%;
  background: #fafafa;
}
.chooseWallet{
  width: 90%;
  margin: 5% auto;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 4px 7px 0 #00000012;
}
.leftMain{width: 20%;}
.centerName{
  width: 80%;
  text-align: center;
  color: #212020;
  font-weight: bold;
  font-size: 18px;
}
.rightMain{
  width: 20%;
  display: flex;
}
.but{
  padding-left: 25%;
}
.chooseWallet .line{
  padding: 5% 5% 0 5%;
  display: flex;
  align-items: baseline;
  font-weight: 400;
  color: #00133A;
}
.chooseWallet .line img{
  margin-right: 5px;
}
.chooseWalletMain{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding:5% ;
}
.content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
}
.el-input-number{
  width: 90% !important;
}
.el-input-number__decrease, .el-input-number__increase{
  background: #246bfd14 !important;
}
.el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
  color: #246BFD !important;
}

.el-submenu__title:hover{
  color: #246BFD !important;
}
.logo2{
  margin: 0 auto;
  display: block;
}
.el-menu{
  border-right:0
}
.navigationIcon{
  margin-right: 8px;
}
.download{
  width: 15%;
  height: 26px;
  border-radius: 8px;
  color: #246bfd;
  border: 1px solid #246bfd;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}
.address, .words, .secret{
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-around;
  margin: 0 5%;
  width: 100%;
}
.address span, .words span, .secret span{
  color: #4D4D4D;
  background: #246bfd0d;
  overflow: hidden;
  width: 84%;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  padding:2%;
  margin:1.5% 0;
}
.address b, .words b, .secret b{
  color: #246BFD;
}
.wallet{
  width: 24%;
  height: 34px;
  border-radius: 6px;
  opacity: 1;
  border: 1px solid #246bfd;
  background: #246bfd;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.el-input__inner{
  border: 0.5px solid #eeefef !important
}
.send,.sendNum,.Content{
  margin: 0 auto;
  width: 98%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3F485A;
}
.contentInput{
  margin: 5% auto;
  width: 100%;
}
.sendMount{
  margin: 2% auto;
  width: 100%;
}
.addressListContent{
  flex-wrap: wrap;
  border: 0.5px solid #eeefef !important;
  border-radius: 8px;
}
.Content{
  color: #C9CDD4;
  margin: 2% 3%;
}
.allButton{
  width: 90%;
  display: flex;
  margin: 5% auto;
  justify-content: space-evenly;
  align-items: center;
}
.sendButton{
  border-radius: 40px;
  padding: 4% 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #246bfd;
  color: #fff;
  font-weight: bold;
}
.start{
  color: #246bfd;
  background: #E4EDFE;
  font-weight: bold;
}
.el-select {
  width: 90%;
  margin: 5%;
}
.text {
  font-size: 14px;
  color: #246bfd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text span {
  display: block;
  font-size: 16px;
  color: #246bfd;
  margin: 0 5px;
  line-height: 30px;
}
.el-button {
  background: #246bfd !important;
  border-radius: 6px;
  opacity: 1;
  border: 1px solid #246bfd;
  background: #246bfd;
  color: #fff;
  font-size: 14px;
}
.el-table {
  margin: 5% 2%;
  width: 96% !important;
}
.el-table thead {
  border-radius: 6px 6px 0 0;
  opacity: 1;
  background: #e4edfe !important;
}
.el-table th.el-table__cell,
.el-table tr {
  background: none !important;
  color: #246bfd !important;
}
.page /deep/ .el-dialog{
  border-radius: 16px !important;
}
.lines{
  padding: 5% 5% 0 5%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 400;
  color: #00133A;
}
.bottom{
  width: 90%;
  margin:0 auto;
  padding-bottom: 20%;
}
.finish,.finishBottom{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.finishBottom{
  margin: 3% auto;
  border: 0.5px solid #e7e8e9;
  border-radius: 8px;
  margin-bottom: 2%;
}
.finishBottomList{
  padding: 2%;
  width: 94%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.finishBottomList span{
  text-align: center;
  color: #545454;
}
.finishBottomList span:nth-child(2){
  width: 50%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>