import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from './router'
// 引用axios
// import axios from 'axios'
// 引用element-ui组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({ 
  locale: 'zh', // 定义默认语言为中文 
  messages: {   
    'zh': require('./assets/language/zh'),   
    'en': require('./assets/language/en'),
  }
});
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(ElementUI)
moment.locale('zh-cn')
Vue.prototype.$moment = moment

new Vue({
  render: h => h(App),
  router,
  i18n,
  beforeCreate() {
    // Vue.prototype.$bus = this
    // Vue.prototype.$http = axios
    // axios.defaults.baseURL = 'http://127.0.0.1:8888/api/private/v1/'
    // // 通过axios请求拦截器添加token
    // axios.interceptors.request.use(config => {
    //   config.headers.Authorization = sessionStorage.getItem('token')
    //   return config
    // })
  }
}).$mount('#app')
