<template>
  <div class="page">
    <div class="top">
        <div class="leftMain">
            <img  src="../assets/img/logo.png" class="showimg" alt="">
        </div>
        <div class="centerName">{{ $t('Batch.title') }}</div>
        <div class="rightMain">
          <div class="language" @click="change()">
            <img v-if="language"  src="../assets/img/en.png" alt="">
            <img v-else src="../assets/img/cn.png" alt="">
          </div>
          <div class="but" @click="toggleDrawer">
            <img src="../assets/img/navigation.png" alt="">
          </div>
        </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      :before-close="handleDrawerClose"
      direction="rtl"
      :size="260"
    >
<!-- 导航组件 -->
      <menulist @handleMenuSelect="handleMenuSelect"></menulist>
    </el-drawer>

    <div class="chooseWallet">
      <!-- 选择批量生成钱包的链 -->
        <span class="line"><img src="../assets/img/decorate.png" alt="">{{ $t('Batch.choose') }}</span>
        <div class="chooseWalletMain">
          <div class="chooseButton" @click="chooseButton('ETH')" :style="buttonStyle('ETH')">ETH</div>
          <div class="chooseButton" @click="chooseButton('BSC')" :style="buttonStyle('BSC')">BSC</div>
          <div class="chooseButton" @click="chooseButton('SOL')" :style="buttonStyle('SOL')">SOL</div>
          <div class="upButton" @click="shwoAllPrize"><img src="../assets//img/generate.png" alt=""></div>
          <div class="chooseButton" v-show="chooseBut">示例</div>
        </div>
    </div>

    <div class="chooseWallet">
      <!-- 选择助记词长度 -->
        <span class="line"><img src="../assets/img/decorate.png" alt="">{{$t('Batch.chooseLength')}}</span>
        <div class="chooseWalletMain">
          <div class="chooseButtonLength" @click="chooseButtonLength('12位')" :style="buttonLength('12位')">12位</div>
          <div class="chooseButtonLength" @click="chooseButtonLength('15位')" :style="buttonLength('15位')">15位</div>
          <div class="chooseButtonLength" @click="chooseButtonLength('18位')" :style="buttonLength('18位')">18位</div>
          <div class="chooseButtonLength" @click="chooseButtonLength('21位')" :style="buttonLength('21位')">21位</div>
          <div class="chooseButtonLength" @click="chooseButtonLength('24位')" :style="buttonLength('24位')">24位</div>
        </div>
    </div>

    <div class="chooseWallet">
      <!-- 生成的钱包地址数量 -->
      <span class="line"><img src="../assets/img/decorate.png" alt="">{{$t('Batch.numberWallet')}}</span>
      <div class="content">
        <el-input-number v-model="num" @change="handleChange" :min="1" :max="300" label="描述文字"></el-input-number>
      </div>
    </div>
    <!-- 立即生成 -->
    <div class="create" @click="create" :style="createBtnBgColor">{{createBtn}} <img src="../assets/img/arrow.png" alt=""></div>

    <div class="progress">
      <div class="progress-bar"><span>Progress: {{ processNumber }} / {{ processTotal }} </span> <div class="download" @click="download">{{$t('Batch.download')}}</div></div>
      <div class="progress-content" v-for="item in results" :key="item.address">
        <div class="address" @click="copy(item.address)"><span>{{$t('Batch.addresses')}} {{ item.address }}</span><b>{{$t('Batch.copy')}}</b></div>
        <div class="words" @click="copy(item.mnemonic)"><span>{{$t('Batch.words')}}  {{ item.mnemonic }}</span><b>{{$t('Batch.copy')}}</b></div>
        <div class="secret" @click="copy(item.privateKey)"><span>{{$t('Batch.secret')}} {{ item.privateKey }}</span><b>{{$t('Batch.copy')}}</b></div>
      </div>
    </div>
  </div>
</template>

<script>
  import menulist from '../components/menu.vue'
  import { getChain } from '../chain/ChainFactory'
  export default {
    components: {
      menulist: menulist,
    },
    data(){
    return{
      selected: 'ETH',
      selectedLength:'12位',
      chooseBut:false,
      num: 1,
      language:false,
      drawerVisible: false,
      results:[],
      processNumber:0,
      processTotal:0,
      loadingState:false,
    }
  },
  computed:{
    createBtn(){
      if(!this.loadingState){
        return this.$t('Batch.title');
      }else{
        return this.$t('Batch.walletLoading');
      }
    },
    createBtnBgColor(){
      return this.loadingState === false ? { backgroundColor: '#246bfd' } : { backgroundColor: '#c0c4cc' };
    }
  },

  methods:{
    download(){
      console.log('下载',this.results);
      const content = this.results.map(obj => `Address: ${obj.address}\nMnemonic: ${obj.mnemonic}\nPrivate Key: ${obj.privateKey}\n`).join('\n');
      console.log(content);
      // 创建一个包含文本内容的 Blob 对象
      const blob = new Blob([content], { type: 'text/plain' });
      // 创建一个 URL，指向该 Blob 对象
      const url = window.URL.createObjectURL(blob);
      // 创建一个链接元素
      const a = document.createElement('a');
      a.href = url;
      // 设置下载属性和文件名
      a.download = 'demo';
      // 模拟点击链接以触发下载
      document.body.appendChild(a);
      a.click();
      // 清理资源
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    handleChange(value) {
      console.log("生成钱包地址数量",value,this.num);
    },
    chooseButtonLength(name){
      this.selectedLength = name;
      console.log(name);
    },
    chooseButton(name) {
      this.selected = name;
      console.log(name);
    },
    buttonLength(button){
      return {
        backgroundColor: this.selectedLength === button ? '#246bfd' : 'white',
        borderColor: this.selectedLength === button ? '#246bfd' : '#246bfd',
        color:this.selectedLength === button ? 'white' : '#246bfd',
      };
    },
    buttonStyle(button) {
      return {
        backgroundColor: this.selected === button ? '#246bfd' : 'white',
        borderColor: this.selected === button ? '#246bfd' : '#246bfd',
        color:this.selected === button ? 'white' : '#246bfd',
      };
    },
    shwoAllPrize(){
      this.chooseBut = !this.chooseBut
      console.log('显示全部按钮');
    },
    change(){
      console.log('切换语言',this.$i18n.locale);
      if(this.$i18n.locale ==='en'){
        this.language=false
        this.$i18n.locale='zh'
      }else if(this.$i18n.locale ==='zh'){
        this.$i18n.locale ='en'
        this.language=true
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    handleDrawerClose() {
      this.drawerVisible = false;
    },
    handleMenuSelect(index) {
      console.log('选中菜单项: ', index);
    },

    async create(){
      if(this.loadingState){
        console.log("正在生成中!");
        return;
      }
      if (this.selected === 'SOL') {
          this.$message.info("SOL链暂未开放,敬请期待!");
          this.createBtn = this.$t('Batch.title');
          return;
       }
      this.loadingState=true;
      try {
        this.results = [];
        this.processTotal = 0;
        this.processNumber = 0;
        let generateQuantity = this.num;
        console.log('批量生成参数', this.selected, this.selectedLength, generateQuantity);
        var chain = getChain(this.selected);
        let mnemonicLength = parseInt(this.selectedLength.substring(0, this.selectedLength.length - 1));
        console.log("助记词长度", mnemonicLength);

        this.processTotal = generateQuantity;
        for (let i = 0; i < this.processTotal; i++) {
          const wallet = await chain.createAccounts(mnemonicLength);
          this.processNumber++;
          this.results.push(wallet);
          this.$forceUpdate(); // 强制 Vue 响应式更新
          await this.sleep(50); // 暂停一段时间以防止阻塞主线程
        }
      } finally {
        this.loadingState=false;
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    copy(val){
      console.log(val);
      var input = document.createElement("input"); // 创建input对象
      input.value = val; // 设置复制内容 +selectedAccount
      console.log("input.value",input.value)
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功！');
    }
  },
  created(){
    var i18n=this.$i18n.locale
      switch (i18n){
        case 'zh':
          this.language=false
          break;
        case 'en':
        this.language=true
          break;
      }
  },
  mounted(){

  }
}
</script>

<style scoped>
body{
  margin: 0;padding: 0;
}
.row :deep(.el-menu) {
  background-color: #eee;
  border-right:0px
}
.row{position: absolute;z-index: 100;left: 0;width: 100%;}
.top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 4%;
}
.page{
  background: #fafafa;
}
.chooseWallet{
  width: 90%;
  margin: 5% auto;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 4px 7px 0 #00000012;
}
.leftMain{width: 20%;}
.centerName{
  width: 80%;
  text-align: center;
  color: #212020;
  font-weight: bold;
  font-size: 18px;
}
.rightMain{
  width: 20%;
  display: flex;
}
.but{
  padding-left: 25%;
}
.chooseWallet .line{
  padding: 5% 5% 0 5%;
  display: flex;
  align-items: baseline;
  font-weight: 400;
  color: #00133A;
}
.chooseWallet .line img{
  margin-right: 5px;
}
.chooseButton{
  width: 26%;
  height: 30px;
  border-radius: 8px;
  display: flex;
  border: 1px solid #246bfd;
  color: #246bfd;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.chooseButtonLength{
  width: 17%;
  height: 30px;
  border-radius: 8px;
  display: flex;
  border: 1px solid #246bfd;
  color: #246bfd;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.chooseWalletMain{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding:5% 5% 0 5%;
}
.upButton{
  margin-bottom: 5%;
  width: 12%;
  height: 30px;
  border-radius: 8px;
  background: #246bfd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
}
.el-input-number{
  width: 90% !important;
}
.el-input-number__decrease, .el-input-number__increase{
  background: #246bfd14 !important;
}
.el-input-number__decrease.is-disabled, .el-input-number__increase.is-disabled{
  color: #246BFD !important;
}
.create{
  width: 80%;
  height: 46px;
  border-radius: 40px;
  margin: 0 auto;
  margin-top: 10%;
  background: #246bfd;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-submenu__title:hover{
  color: #246BFD !important;
}
.logo2{
  margin: 0 auto;
  display: block;
}
.el-menu{
  border-right:0
}
.navigationIcon{
  margin-right: 8px;
}
.download{
  height: 26px;
  padding:.5% 2%;
  border-radius: 8px;
  color: #246bfd;
  border: 1px solid #246bfd;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
}
.progress-bar{
  margin: 0 auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-bar span{
  color: #00133a;
  font-weight: bold;
}
.progress{
  margin: 10% auto;
  padding-bottom: 20%;
}
.progress-content{
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3%;
}
.address, .words, .secret{
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.address span, .words span, .secret span{
  color: #4D4D4D;
  background: #246bfd0d;
  overflow: hidden;
  width: 84%;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 8px;
  padding:2%;
  margin:1.5% 0;
}
.address b, .words b, .secret b{
  color: #246BFD;
}
</style>
