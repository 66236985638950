<template>
  <div>
    <img class="logo2" src="../assets/img/logo2.png" alt="" />
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      @select="handleMenuSelect"
      router
    >
      <el-submenu index="1">
        <!-- 钱包工具 -->
        <template slot="title"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon1.png"
            alt="" />{{ $t('Batch.wallet') }}</template>
        <!-- 批量生成工具 -->
        <el-menu-item index="generate"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon2.png"
            alt="" />{{ $t('Batch.title') }}</el-menu-item>
        <!-- 靓号钱包生成 -->
        <el-menu-item index="" @click="prompt"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon3.png"
            alt="" />{{ $t('Batch.beautiful') }}</el-menu-item>
        <!-- 钱包安全检测 -->
        <el-menu-item index="" @click="prompt"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon4.png"
            alt="" />{{ $t('Batch.security') }}</el-menu-item>
        <!-- NFT 授权检查 -->
        <el-menu-item index="" @click="prompt"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon5.png"
            alt="" />{{ $t('Batch.NFTcheck') }}</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <!-- 代币工具 -->
        <template slot="title"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon6.png"
            alt="" />{{ $t('Batch.tokenTools') }}</template>
        <!-- 批量发送 -->
        <el-menu-item index="sending"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon2.png"
            alt="" />{{ $t('Batch.sending') }}</el-menu-item>
        <!-- 批量归集 -->
        <el-menu-item index="imputation"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon3.png"
            alt="" />{{ $t('Batch.imputation') }}</el-menu-item >
        <!-- 批量查余额 -->
        <el-menu-item index="balance"><img
            class="navigationIcon"
            src="../assets/img/navigation_icon4.png"
            alt="" />{{ $t('Batch.balance') }}</el-menu-item>
      </el-submenu>
      <!-- NFT工具 -->
      <el-menu-item index="" @click="prompt"><img
          class="navigationIcon"
          src="../assets/img/navigation_icon7.png"
          alt="" />{{ $t('Batch.NFTtool') }}</el-menu-item>
      <!-- 铭文工具 -->
      <el-menu-item index="" @click="prompt"><img
          class="navigationIcon"
          src="../assets/img/navigation_icon8.png"
          alt="" />{{ $t('Batch.inscriptionTool') }}</el-menu-item>
      <!-- 其他工具 -->
      <el-menu-item index="other"><img
          class="navigationIcon"
          src="../assets/img/navigation_icon9.png"
          alt="" />{{ $t('Batch.other') }}</el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  methods: {
    handleMenuSelect(index) {
      this.$emit('handleMenuSelect', index)
    },
    prompt(){
      this.$message.info("当前功能未开放,敬请期待!");
    }
  },
}
</script>
<style>
.logo2 {
  margin: 0 auto;
  display: block;
}
.el-menu {
  border-right: 0;
}
.navigationIcon {
  margin-right: 8px;
}
</style>
