const ChainCfg = {
    1: {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    3: {
        chainId: '0x3',
        chainName: 'Ropsten testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://ropsten.etherscan.io'],
    },
    42: {
        chainId: '0x2a',
        chainName: 'Kovan testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://endpoints.omniatech.io/v1/eth/mainnet/public'],
        blockExplorerUrls: ['https://kovan.etherscan.io'],
    },
    56: {
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://rpc.ankr.com/bsc'],
        blockExplorerUrls: ['https://bscscan.com/'],
    },
    97: {
        chainId: '0x61',
        chainName: 'Binance Smart Chain - TestNet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
    1088: {
        chainId: '0x440',
        chainName: 'Maas - TestNet',
        nativeCurrency: {
            name: 'Maas',
            symbol: 'Maas',
            decimals: 18,
        },
        rpcUrls: ['https://maas-test-node.onchain.com/'],
        blockExplorerUrls: ['https://maas-test-explorer.onchain.com/'],
    },
    2088: {
        chainId: '0x828',
        chainName: 'Maas',
        nativeCurrency: {
            name: 'Maas',
            symbol: 'Maas',
            decimals: 18,
        },
        rpcUrls: ['https://maas-node.onchain.com/'],
        blockExplorerUrls: ['https://maas-explorer.onchain.com/'],
    },
};

export default ChainCfg;