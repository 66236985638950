<template>
  <div class="page">
    <div class="top">
      <div class="leftMain">
        <img src="../assets/img/logo.png" class="showimg" alt="" />
      </div>
      <div class="centerName">{{ $t('Batch.other') }}</div>
      <div class="rightMain">
        <div class="language" @click="change()">
          <img v-if="language" src="../assets/img/en.png" alt="" />
          <img v-else src="../assets/img/cn.png" alt="" />
        </div>
        <div class="but" @click="toggleDrawer">
          <img src="../assets/img/navigation.png" alt="" />
        </div>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      :before-close="handleDrawerClose"
      direction="rtl"
      :size="260"
    >
<!-- 导航组件 -->
      <menulist @handleMenuSelect="handleMenuSelect"></menulist>
    </el-drawer>
    <div class="noMessage">
      <img src="../assets/img/Default.png" alt="">
    </div>
    
  </div>
</template>

<script>
import menulist from '../components/menu.vue'
export default {
  components: {
    menulist: menulist,
  },
  data() {
    return {
      language: false,
      drawerVisible: false,
      dialog:false,
    }
  },
  methods: {
    change() {
      console.log('切换语言', this.$i18n.locale)
      if (this.$i18n.locale === 'en') {
        this.language = false
        this.$i18n.locale = 'zh'
      } else if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en'
        this.language = true
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible
    },
    handleDrawerClose() {
      this.drawerVisible = false
    },
    handleMenuSelect(index) {
      console.log('选中菜单项: ', index)
    },
    toDO() {
      this.dialog = true;
    },
  },
  created() {
    var i18n = this.$i18n.locale
    switch (i18n) {
      case 'zh':
        this.language = false
        break
      case 'en':
        this.language = true
        break
    }
  },
  mounted() {
    let Main = document.querySelector('.page')
    let MainW = Main.getBoundingClientRect().width
    Main.style.height = (MainW / 750) * 1500 + 'px'
  },
}
</script>

<style scoped>
body{
  margin: 0;padding: 0;
}
.row :deep(.el-menu) {
  background-color: #eee;
  border-right: 0px;
}
.row {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100%;
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 4%;
}
.page {
  background: #fafafa;
}
.chooseWallet {
  width: 90%;
  margin: 5% auto;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0 4px 7px 0 #00000012;
}
.leftMain{width: 20%;}
.centerName{
  width: 80%;
  text-align: center;
  color: #212020;
  font-weight: bold;
  font-size: 18px;
}
.rightMain{
  width: 20%;
  display: flex;
}
.but{
  padding-left: 25%;
}
.noMessage{
  width: 100%;
}
.noMessage img{
  margin: 0 auto;
  display: block;
  margin-top: 20%;
}
</style>